import React, { useEffect } from 'react';
import SEO from '../../Seo';
import Card from '../../common/Card';
import { useMutation, useQuery } from 'react-query';
import {
  createUserOrganizationInvitation,
  getEventRegistrationRequest,
  getEventRegistrationRequestUsers,
  getGrades,
  getOrganizations,
  getPositions,
  submitEventRegistrationRequestAttendees,
  updateEventRegistrationRequestSeats,
  uploadEventRegistrationRequestPurchaseOrder
} from '../api';
import Button from '../../common/Button';
import UserSelect from './../UserSelect';
import { formatDateRange } from '../../../util/date';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { formatAsCurrency } from '../../../util';
import { toast } from 'react-toastify';
import ModalWrapper from '../../common/ModalWrapper';
import PurchaseOrdersInstitute, {
  PurchaseOrdersInstituteForm
} from '../PurchaseOrdersInstitute';
import NewStaffMember, { NewStaffMemberForm } from '../NewStaffMember';
import { useFetch } from '../../../hooks/useFetch';
import moment from 'moment';
import { QueryReturnState } from '../Enum';
import { IGrade, IPosition } from '../NewApiTypes';
import { navigate } from 'gatsby';
import ApplicationPaymentStatus, {
  EventPaymentStatus
} from '../EventApplication/ApplicationPaymentStatus';
import classNames from 'classnames';

const getEventDates = (event: any) => {
  if (!event?.isRangeDate) {
    return formatDateRange(event?.dates);
  }
  if (event?.startDate !== event?.endDate) {
    return formatDateRange([event?.startDate, event?.endDate]);
  }
  return formatDateRange([event?.startDate]);
};

const getEventTimes = (event: any) => {
  return '';
};

const isPaid = (eventRegistrationRequest: any) => {
  return [
    EventPaymentStatus.INVOICED,
    EventPaymentStatus.PAID,
    EventPaymentStatus.COMPLIMENTARY
  ].includes(eventRegistrationRequest?.payment?.status);
};

const isPaymentExists = (eventRegistrationRequest: any) => {
  return (
    !!eventRegistrationRequest?.payment?.status &&
    eventRegistrationRequest.payment.status !== EventPaymentStatus.PENDING
  );
};

const statusText = (status: EventPaymentStatus) => {
  let text;
  switch (status) {
    case EventPaymentStatus.PAID:
    case EventPaymentStatus.COMPLIMENTARY:
      text = 'Paid';
      break;
    case EventPaymentStatus.AWAITING_PO:
      text = 'Awaiting Puchase Order';
      break;
    case EventPaymentStatus.PENDING:
      text = 'Payment Incomplete';
      break;
    case EventPaymentStatus.PO_SUBMITTED:
      text = 'Purchase order submitted';
      break;
    case EventPaymentStatus.AWAITING_INVOICE:
      text = 'Invoice pending';
      break;
    case EventPaymentStatus.INVOICED:
      text = 'Invoice sent';
      break;
    default:
      text = 'Not Paid';
  }
  return text;
};

const EventRegistrationRequest = (props: { requestId: string }) => {
  const [isEditSeats, setIsEditSeats] = React.useState(false);
  const [inputSeats, setInputSeats] = React.useState(0);
  const [isOpenPOModal, setIsOpenPOModal] = React.useState(false);
  const [isOpenAddAttendeeModal, setIsOpenAddAttendeeModal] = React.useState(
    false
  );
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);

  const { data: gradesData } = useQuery<{
    state: QueryReturnState;
    grades?: IGrade[];
  }>('grades', getGrades);
  const { data: positionsData } = useQuery<{
    state: QueryReturnState;
    positions?: IPosition[];
  }>('positions', getPositions);
  const {
    data: organizationsData,
    isLoading: isLoadingOrganizations
  } = useQuery<{
    state: QueryReturnState;
    organizations?: any[];
    // @ts-ignore
  }>('get-organizations', getOrganizations);
  const {
    data: usersSelectData,
    isLoading: isLoadingUsers,
    refetch: refetchUsersData
  } = useQuery('users-select', getEventRegistrationRequestUsers);
  const { isFetching, error, data, refetch } = useQuery<
    { eventRegistrationRequest: any; price: number },
    Error
  >(['getEventRegistrationRequest', props.requestId], async () => {
    const response = await getEventRegistrationRequest(props.requestId);
    if (response?.state !== 'SUCCESS') {
      throw new Error(response?.message || 'Something went wrong');
    }
    return response;
  });

  const { isLoading: isLoadingSeats, mutateAsync: updateSeats } = useMutation(
    async (seats: number) => {
      const response = await updateEventRegistrationRequestSeats(
        props.requestId,
        seats
      );
      return response;
    }
  );

  const { isLoading: isLoadingPO, mutateAsync: uploadPO } = useMutation(
    async (data: any) => {
      const response = await uploadEventRegistrationRequestPurchaseOrder(data);
      return response;
    }
  );

  const { isLoading: isLoadingSubmit, mutateAsync: handleSubmit } = useMutation(
    async (data: any) => {
      const response = await submitEventRegistrationRequestAttendees(
        props.requestId,
        data
      );
      return response;
    }
  );

  useEffect(() => {
    if (data?.eventRegistrationRequest) {
      setInputSeats(data?.eventRegistrationRequest?.seatsRequested);
      const users = data?.eventRegistrationRequest?.eventApplications?.map(
        (ev: any) => ({
          id: ev.user?.id,
          value: ev.user?.id,
          label: `${ev.user?.firstName} ${ev.user?.lastName}`,
          email: ev.user?.email
        })
      );
      setSelectedUsers(users || []);
    }
  }, [data]);

  useEffect(() => {
    const newSelectedUsers = Array.from({
      length: inputSeats
    }).map(() => null);
    for (const [index, user] of selectedUsers.entries()) {
      newSelectedUsers[index] = user || null;
    }
  }, [inputSeats]);

  const submitNewSeats = async () => {
    try {
      const response = await updateSeats(inputSeats);
      if (response?.state !== 'SUCCESS') {
        throw new Error(response?.message || 'Something went wrong');
      }
      await refetch();
      setIsEditSeats(false);
    } catch (e) {
      toast.error((e as any)?.message || 'Something went wrong');
    }
  };

  const createUserOrganizationInvitationFetch = useFetch(
    createUserOrganizationInvitation
  );

  const submitAddNewStaffMember = async (_values: NewStaffMemberForm) => {
    const { organization, ...values } = _values;
    const {
      data: createdUser,
      state,
      error
    } = await createUserOrganizationInvitationFetch.apply({
      ...values,
      dateOfBirth: values.dateOfBirth
        ? moment(values.dateOfBirth, 'MM/DD/YYYY').toISOString()
        : null,
      organizationId: organization?.value,
      ssnLastFour: Number(values.ssnLastFour)
    });

    if (state === 'SUCCESS') {
      await refetchUsersData();

      toast.success(
        `Success! ${createdUser?.user?.firstName} ${createdUser?.user?.lastName} (${createdUser?.user?.email}) has now been invited to selected school.`
      );

      setIsOpenAddAttendeeModal(false);
    } else {
      toast.error(error || createdUser?.message || 'Something went wrong');
    }
  };

  const isNoOrganizations =
    (organizationsData as any)?.organizations?.length === 0 &&
    !isLoadingOrganizations;

  const usersOptions =
    usersSelectData?.users?.map((user: any) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
      email: user.email,
      isPending: user.isPending
    })) || [];

  const price = data?.price || 0;

  const statusDescriptionClasses = (evRegReq: any) =>
    classNames({
      'sans-serif': true,
      'text-yellow-600': !isPaid(evRegReq),
      'text-green-600': isPaid(evRegReq)
    });

  const onPOSubmit = async (
    values: PurchaseOrdersInstituteForm & { type: any }
  ) => {
    const { applicants: _a, type: _t, institute: _i, ..._payload } = values;

    const payload = {
      ..._payload,
      eventRegistrationRequestId: props.requestId
    };

    try {
      const response = await uploadPO(payload);
      if (response?.state !== 'SUCCESS') {
        throw new Error(response?.message || 'Something went wrong');
      }
      await refetch();
      setIsOpenPOModal(false);
    } catch (e) {
      toast.error((e as any)?.message || 'Something went wrong');
    }
  };

  const onSubmit = async () => {
    try {
      if (
        selectedUsers.filter(Boolean)?.length !==
        data?.eventRegistrationRequest?.seatsRequested
      ) {
        throw new Error('Please fill all the seats');
      }
      const payload = { users: selectedUsers.map(u => u.id) };
      const response = await handleSubmit(payload);
      if (response?.state !== 'SUCCESS') {
        throw new Error(response?.message || 'Something went wrong');
      }
      await refetch();
      toast.success('Successfully saved');
    } catch (e) {
      toast.error((e as any)?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <SEO title="Event Registration Request" />
      {isOpenAddAttendeeModal && (
        <NewStaffMember
          positions={positionsData?.positions || []}
          grades={gradesData?.grades || []}
          onCancel={() => setIsOpenAddAttendeeModal(false)}
          onSubmit={userData => submitAddNewStaffMember(userData)}
          isSubmitting={false}
          isForSimplifiedRegistration
        />
      )}
      <ModalWrapper
        isOpen={isOpenPOModal && !!data?.eventRegistrationRequest?.event?.id}
        onClose={() => setIsOpenPOModal(false)}
      >
        <PurchaseOrdersInstitute
          isForSimplifiedRegistration
          eventId={data?.eventRegistrationRequest?.event?.id}
          onCancel={() => setIsOpenPOModal(false)}
          onSubmit={onPOSubmit}
          isSubmitLoading={isLoadingPO}
        />
      </ModalWrapper>
      <Card>
        <h3 className="text-primary font-bold text-2xl mb-2">
          Event Registration
        </h3>
        {(isFetching || isLoadingOrganizations) && <p>Loading...</p>}
        {error && <p>Error: {error?.message}</p>}
        {data && (
          <>
            <h1 className="text-gray-700 text-xl font-semibold">
              {data.eventRegistrationRequest?.event?.name}
            </h1>
            <div className="text-gray-600">
              <p className="sans-serif">
                {getEventDates(data.eventRegistrationRequest?.event)}
              </p>
              <p className="sans-serif">
                {getEventTimes(data.eventRegistrationRequest?.event)}
              </p>
            </div>
            <div className="mt-6 bg-primary rounded-lg p-4 text-white">
              <section className="w-2/3">
                <p>
                  Thank you for registering for the{' '}
                  {data.eventRegistrationRequest?.event?.name} institute. Here
                  you will find information about your registration and tools
                  for managing your payment and attendees.
                </p>
              </section>
            </div>
            <div className="mt-6">
              <h2 className="text-primary font-bold text-xl mb-2">
                Registration Details
              </h2>
              <div className="grid grid-cols-3 gap-4 mt-2">
                <div>
                  <h3 className="text-gray-700 font-semibold">Attendees</h3>
                  {isLoadingSeats ? (
                    <>Loading...</>
                  ) : (
                    <>
                      {!isPaymentExists(data?.eventRegistrationRequest) &&
                      isEditSeats ? (
                        <></>
                      ) : (
                        <p className="text-gray-600">{inputSeats}</p>
                      )}
                      {!isPaymentExists(data?.eventRegistrationRequest) && (
                        <div>
                          {isEditSeats ? (
                            <div className="flex gap-2">
                              <input
                                name="seats"
                                type="number"
                                max={100}
                                min={1}
                                className="border border-gray-400 rounded-md p-1 w-1/2"
                                value={inputSeats}
                                onChange={e => setInputSeats(+e.target.value)}
                                disabled={isLoadingSeats}
                              />
                              <Button
                                text="Save"
                                type="button"
                                color="main"
                                size="sm"
                                onClick={() => submitNewSeats()}
                                isLoading={isLoadingSeats}
                                icon={faCheck}
                                className="flex items-center"
                              />
                              <Button
                                text="Cancel"
                                type="button"
                                color="error"
                                onClick={() => {
                                  setIsEditSeats(false);
                                  setInputSeats(
                                    data?.eventRegistrationRequest
                                      ?.seatsRequested
                                  );
                                }}
                                size="sm"
                                disabled={isLoadingSeats}
                                icon={faTimes}
                                className="flex items-center"
                              />
                            </div>
                          ) : (
                            <button
                              type="button"
                              className="text-primary text-xs font-bold underline cursor-pointer"
                              onClick={() => setIsEditSeats(true)}
                            >
                              Modify
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <h3 className="text-gray-700 font-semibold">Total Cost</h3>
                  <p className="text-gray-600">
                    {formatAsCurrency(inputSeats * price)}
                  </p>
                </div>
                <div>
                  <h3 className="text-gray-700 font-semibold">
                    Payment Status
                  </h3>
                  <div className="">
                    <p
                      className={statusDescriptionClasses(
                        data?.eventRegistrationRequest
                      )}
                    >
                      {statusText(
                        data?.eventRegistrationRequest?.payment?.status
                      )}
                    </p>
                    {!isPaymentExists(data?.eventRegistrationRequest) && (
                      <div className="mt-2">
                        <Button
                          text="Pay with PO"
                          size="sm"
                          onClick={() => setIsOpenPOModal(true)}
                          className="mr-2"
                        />
                        <Button text="Pay with Credit Card" size="sm" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex gap-x-4">
                <h2 className="text-primary font-bold text-xl mb-2">
                  Event Attendees
                </h2>
                <div>
                  <Button
                    text="Add User"
                    size="sm"
                    type="button"
                    onClick={() => setIsOpenAddAttendeeModal(true)}
                    disabled={isNoOrganizations}
                  />
                </div>
              </div>
              {isNoOrganizations && (
                <div>
                  <p className="text-red-600">
                    You must have at least one organization affiliation
                  </p>
                  <div className="mt-1 sans-serif">
                    <span
                      onClick={() => navigate('/members/profile')}
                      className="text-primary cursor-pointer sans-serif"
                    >
                      Click here{' '}
                    </span>{' '}
                    to add an organization affiliation
                  </div>
                </div>
              )}
              {Array.from({
                length: data?.eventRegistrationRequest?.seatsRequested
              }).map((_, index) => (
                <div className="mt-2" key={index}>
                  <p className="mb-1 text-gray-600 font-bold">
                    Seat {index + 1}
                  </p>
                  <div className="mb-3 w-1/2">
                    <UserSelect
                      isDisabled={isNoOrganizations}
                      isLoading={isLoadingUsers || isLoadingOrganizations}
                      // @ts-ignore
                      value={selectedUsers[index]}
                      options={usersOptions.filter((opt: any) => {
                        return !selectedUsers.some(
                          selectedUser => selectedUser?.id === opt.value
                        );
                      })}
                      onChange={(value: any) => {
                        if (
                          !selectedUsers.some(user => user?.id === value.value)
                        ) {
                          const newSelectedUsers = [...selectedUsers];
                          newSelectedUsers[index] = {
                            ...value,
                            id: value.value
                          };
                          setSelectedUsers(newSelectedUsers);
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-end w-full">
              <Button
                text="Save"
                size="sm"
                onClick={onSubmit}
                isLoading={isLoadingSubmit}
                disabled={
                  selectedUsers.filter(Boolean)?.length !==
                  data?.eventRegistrationRequest?.seatsRequested
                }
              />
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default EventRegistrationRequest;
